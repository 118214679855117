import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-15476735"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "core-value-conten"
}
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "border-box" }
const _hoisted_4 = { class: "box-title" }
const _hoisted_5 = { class: "box-desc" }
const _hoisted_6 = { class: "card-box-container" }
const _hoisted_7 = { class: "card-question-type" }
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = { class: "card-des" }
const _hoisted_10 = {
  key: 1,
  class: "core-value-conten"
}
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "border-box" }
const _hoisted_13 = { class: "box-title" }
const _hoisted_14 = { class: "box-desc" }
const _hoisted_15 = { class: "card-box-container" }
const _hoisted_16 = { class: "card-question-type" }
const _hoisted_17 = { class: "card-title" }
const _hoisted_18 = { class: "card-des" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.questionsLoaded || _ctx.locked)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Questions")), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Regularly_get_your_employees")), 1),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2], (question, index) => {
                return _createElementVNode("div", {
                  key: index + '_question_mock',
                  class: _normalizeClass(["guidance-card", `${question > 1 ? 'opa-08' : ''}`])
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", {
                      class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 br-4"]),
                      style: {"width":"10%"}
                    }, "   ", 2)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", {
                      class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-05 br-4"]),
                      style: {"width":"40%"}
                    }, "   ", 2)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i2) => {
                      return _createElementVNode("div", {
                        key: i2 + '_mock_text',
                        class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 mt-2 br-4"]),
                        style: {"width":"100%"}
                      }, "   ", 2)
                    }), 64)),
                    _createElementVNode("div", {
                      class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 mt-2 br-4"]),
                      style: {"width":"80%"}
                    }, "   ", 2)
                  ])
                ], 2)
              }), 64))
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Questions")), 1),
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Regularly_get_your_employees")), 1),
            _createElementVNode("div", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionsData.questionsData, (question, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index + '_question',
                  class: "guidance-card"
                }, [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(question.questionType), 1),
                  _createElementVNode("div", _hoisted_17, _toDisplayString(question.questionLabel), 1),
                  _createElementVNode("div", _hoisted_18, _toDisplayString(question.skillDescription), 1)
                ]))
              }), 128))
            ])
          ])
        ])
      ]))
}